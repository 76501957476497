import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localePlExtra from '@angular/common/locales/extra/pl';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { Router } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { NgxHotjarModule } from 'ngx-hotjar';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoaderFactory } from './i18n/factories/translate.factory';
import { CurrentLanguageService } from './i18n/services/current-language.service';
import { AppInitializerFactory } from './core/factories/app-initializer.factory';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { AuthenticationService } from './auth/services/authentication.service';
import { LanguageInterceptor } from './i18n/interceptors/language-interceptor.service';
import { LayoutModule } from './layout/layout.module';
import { CurrentSettingsService } from './settings/services/current-settings.service';
import { StrapiInterceptor } from './strapi/interceptors/strapi.interceptor';
import { UserVerificationService } from './user-verification/services/user-verification.service';
import { environment } from '../environments/environment';
import { NotFoundComponent } from './core/pages/not-found/not-found.component';

registerLocaleData(localePl, 'pl', localePlExtra);

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxHotjarModule.forRoot(environment.hotjar.trackingCode, environment.hotjar.version),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    NgbModule,
    ToastNoAnimationModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 10000,
    }),
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [CurrentLanguageService, AuthenticationService, CurrentSettingsService, UserVerificationService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StrapiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
