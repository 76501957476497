import { HttpParams } from '@angular/common/http';

import { CustomHttpParamEncoder } from '../../core/encoders/custom-http-params.encoder';

export class HttpParamsUtil {
  public static mapFiltersToHttpParams<T>(obj: T | null): HttpParams {
    const params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });

    if (obj === null) {
      return params;
    }

    return HttpParamsUtil.populateSearchParams(params, '', obj);
  }

  private static populateArray<T>(params: HttpParams, prefix: string, val: Array<T>): HttpParams {
    val.forEach((item: T, index: number) => {
      const key: string = `${prefix}[${index}]`;
      const value: any = item;
      params = HttpParamsUtil.populateSearchParams(params, key, value);
    });

    return params;
  }

  private static populateObject<T>(params: HttpParams, prefix: string, val: T): HttpParams {
    // @ts-ignore
    const objectKeys: Array<keyof T> = Object.keys(val) as Array<keyof T>;

    objectKeys.forEach((objKey: keyof T) => {
      const value: T[keyof T] = val[objKey];
      let key: string = prefix;
      if (prefix) {
        key += `[${objKey as string}]`;
      } else {
        key += objKey as string;
      }

      params = HttpParamsUtil.populateSearchParams(params, key, value);
    });

    return params;
  }

  private static populateSearchParams(params: HttpParams, key: string, value: any): HttpParams {
    if (value instanceof Array) {
      return HttpParamsUtil.populateArray(params, key, value);
    }
    if (value instanceof Date) {
      return params.append(key, value.toISOString());
    }
    if (value instanceof Object) {
      return HttpParamsUtil.populateObject(params, key, value);
    }
    if ('undefined' !== typeof value && null !== value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
      return params.append(key, value.toString());
    }
    return params;
  }
}
