import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appTextareaAutoresize]',
})
export class TextareaAutoresizeDirective implements OnInit {
  @Input() public appTextareaAutoresize: boolean;

  constructor(private elementRef: ElementRef) {
    this.appTextareaAutoresize = true;
  }

  @HostListener(':input')
  public onInput(): void {
    if (!this.appTextareaAutoresize) {
      return;
    }
    this.resize();
  }

  public ngOnInit(): void {
    if (!this.appTextareaAutoresize) {
      return;
    }

    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  public resize(): void {
    this.elementRef.nativeElement.style.height = '0';
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/restrict-plus-operands
    this.elementRef.nativeElement.style.height = `${this.elementRef.nativeElement.scrollHeight + 2}px`;
  }
}
