import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.currentUser.pipe(
      tap((user: UserModel | null) => {
        if (user) {
          this.router.navigate(['/dashboard']);
        }
      }),
      map((user: UserModel | null) => !user)
    );
  }
}
