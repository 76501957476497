import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { DashboardNavbarItemModel } from '../models/dashboard-navbar-item.model';
import { UserModel } from '../../auth/models/user.model';
import { CompanyTypeEnum } from '../../auth/enums/company-type.enum';
import { AuthenticationService } from '../../auth/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardNavbarService {
  public currentDashboardNavbarItems: Observable<Array<DashboardNavbarItemModel> | null>;
  private currentDashboardNavbarItemsSubject: BehaviorSubject<Array<DashboardNavbarItemModel> | null>;

  constructor(private authenticationService: AuthenticationService) {
    this.currentDashboardNavbarItemsSubject = new BehaviorSubject<Array<DashboardNavbarItemModel> | null>(null);
    this.currentDashboardNavbarItems = this.currentDashboardNavbarItemsSubject.asObservable();
    this.initDashboardNavbarItems();
  }

  private getDashboardNavbarItems(isPetsitter: boolean): Array<DashboardNavbarItemModel> {
    if (!isPetsitter) {
      return [
        {
          icon: '../../../../assets/icons/dog_house.svg',
          path: '/dashboard/dashboard',
          title: 'layout.dashboardLayout.dashboard',
        },
        {
          icon: '../../../../assets/icons/calendar.svg',
          path: '/dashboard/calendar',
          title: 'layout.dashboardLayout.calendar',
        },
        {
          icon: '../../../../assets/icons/orders.svg',
          path: '/dashboard/orders',
          title: 'layout.dashboardLayout.orders',
        },
        {
          icon: '../../../../assets/icons/leadership.svg',
          path: '/dashboard/profile/basic-user',
          title: 'layout.dashboardLayout.userProfile',
        },
        {
          icon: '../../../../assets/icons/settings.svg',
          path: '/dashboard/settings',
          title: 'layout.dashboardLayout.settings',
        },
      ];
    }

    return [
      {
        icon: '../../../../assets/icons/dog_house.svg',
        path: '/dashboard/dashboard',
        title: 'layout.dashboardLayout.dashboard',
      },
      {
        icon: '../../../../assets/icons/calendar.svg',
        path: '/dashboard/calendar',
        title: 'layout.dashboardLayout.calendar',
      },
      {
        icon: '../../../../assets/icons/orders.svg',
        path: '/dashboard/orders',
        title: 'layout.dashboardLayout.orders',
      },
      {
        icon: '../../../../assets/icons/donation.svg',
        path: '/dashboard/earnings',
        title: 'layout.dashboardLayout.earnings',
      },
      {
        icon: '../../../../assets/icons/services.svg',
        path: '/dashboard/services',
        title: 'layout.dashboardLayout.services',
      },
      {
        icon: '../../../../assets/icons/leadership.svg',
        path: '/dashboard/profile',
        title: 'layout.dashboardLayout.userProfile',
      },
      {
        icon: '../../../../assets/icons/settings.svg',
        path: '/dashboard/settings',
        title: 'layout.dashboardLayout.settings',
      },
    ];
  }

  private initDashboardNavbarItems(): void {
    this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      if (!user) {
        return;
      }

      const dashboardNavbarItems: Array<DashboardNavbarItemModel> = this.getDashboardNavbarItems(
        user.companyType !== CompanyTypeEnum.owner
      );
      this.currentDashboardNavbarItemsSubject.next(dashboardNavbarItems);
    });
  }
}
