<ng-container *ngIf="strapiFooterDescription">
  <footer>
    <div class="container">
      <div class="footer-top">
        <div class="footer-left">
          <a [href]="getMainLink()">
            <img src="{{ strapiFooterDescription.logo.data.attributes.url | strapiMediaUrl }}" alt="PawAway Logo" />
          </a>
          <p>{{ strapiFooterDescription.aboutCompany }}</p>

          <ul>
            <li *ngFor="let socialMediaLink of strapiFooterDescription.socialMediaLinks">
              <a target="_blank" href="{{ socialMediaLink.link }}"><i class="{{ socialMediaLink.icon }}"></i></a>
            </li>
          </ul>
        </div>

        <div class="footer-right">
          <div class="footer-right-inner">
            <h4>{{ strapiFooterDescription.columnOneTitle }}</h4>
            <ul>
              <li *ngFor="let columnOneLink of strapiFooterDescription.columnOneLinks">
                <a target="_blank" href="{{ columnOneLink.url }}">{{ columnOneLink.text }}</a>
              </li>
            </ul>
          </div>

          <div class="footer-right-inner">
            <h4>{{ strapiFooterDescription.columnTwoTitle }}</h4>
            <ul>
              <li *ngFor="let columnTwoLink of strapiFooterDescription.columnTwoLinks">
                <a
                  *ngIf="columnTwoLink.url === '#pawCoverModal'; else isNotModal"
                  class="pointer-cursor"
                  data-bs-toggle="modal"
                  data-bs-target="#pawCoverModal"
                >
                  {{ columnTwoLink.text }}
                </a>
                <ng-template #isNotModal>
                  <a target="_blank" href="{{ columnTwoLink.url }}">{{ columnTwoLink.text }}</a>
                </ng-template>
              </li>
            </ul>
          </div>

          <div class="footer-right-inner">
            <h4>{{ strapiFooterDescription.columnThreeTitle }}</h4>
            <ul>
              <li *ngFor="let columnThreeLink of strapiFooterDescription.columnThreeLinks">
                <a target="_blank" href="{{ columnThreeLink.url }}">{{ columnThreeLink.text }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <p>{{ strapiFooterDescription.copyright }}</p>
      </div>
    </div>
  </footer>
</ng-container>
