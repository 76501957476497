<div class="navbar-banner">
  <a class="pointer-cursor" (click)="acceptAccount()">Akceptuje konto</a>

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

  <a class="pointer-cursor" (click)="showDeclineAccount()">Nie akceptuje konta</a>

  <div class="row" *ngIf="isReasonControlVisible">
    <app-form-control-textarea
      uid="showReasonControl"
      label="Dlaczego?"
      [formControl]="reasonControl"
      [isRequiredAsteriskVisible]="true"
      [canShowErrors]="true"
      [showCounter]="true"
      [maxLength]="200"
      (keydown.enter)="declineAccount($event)"
    ></app-form-control-textarea>
  </div>
</div>
