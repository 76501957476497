import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { StrapiRequestModel } from '../models/strapi-request.model';
import { environment } from '../../../environments/environment';
import { HttpParamsUtil } from '../../shared/utils/http-params.util';
import { StrapiResponseModel } from '../models/strapi-response.model';
import { StrapiDataModel } from '../models/strapi-data.model';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {
  constructor(private httpClient: HttpClient) {}

  public getList<T>(
    resource: string,
    strapiData: StrapiRequestModel | null = null
  ): Observable<StrapiResponseModel<Array<StrapiDataModel<T>>>> {
    const params: HttpParams = HttpParamsUtil.mapFiltersToHttpParams<StrapiRequestModel>(strapiData);

    return this.httpClient.get<StrapiResponseModel<Array<StrapiDataModel<T>>>>(`${environment.strapi.url}${resource}`, { params });
  }

  public getSingle<T>(
    resource: string,
    resourceId: number | null,
    strapiData: StrapiRequestModel | null = null
  ): Observable<StrapiResponseModel<StrapiDataModel<T>>> {
    const params: HttpParams = HttpParamsUtil.mapFiltersToHttpParams<StrapiRequestModel>(strapiData);

    return this.httpClient.get<StrapiResponseModel<StrapiDataModel<T>>>(
      `${environment.strapi.url}${resource}${resourceId ? `/${resourceId}` : ''}`,
      { params }
    );
  }
}
