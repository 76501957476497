import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { UserVerificationAlertComponent } from './components/user-verification-alert/user-verification-alert.component';
import { WaitingForVerificationAlertComponent } from './components/waiting-for-verification-alert/waiting-for-verification-alert.component';
import { VerificationFailedAlertComponent } from './components/verification-failed-alert/verification-failed-alert.component';
import { UserVerificationRoutingModule } from './user-verification-routing.module';
import { VerifyUserComponent } from './components/verify-user/verify-user.component';
import { VerificationAdminAlertComponent } from './components/verification-admin-alert/verification-admin-alert.component';
import { FormsModule } from '../forms/forms.module';

@NgModule({
  declarations: [
    UserVerificationAlertComponent,
    WaitingForVerificationAlertComponent,
    VerificationFailedAlertComponent,
    VerifyUserComponent,
    VerificationAdminAlertComponent,
  ],
  imports: [CommonModule, RouterLink, TranslateModule, UserVerificationRoutingModule, ReactiveFormsModule, FormsModule, AngularFormsModule],
  exports: [
    UserVerificationAlertComponent,
    WaitingForVerificationAlertComponent,
    VerificationFailedAlertComponent,
    VerificationAdminAlertComponent,
  ],
})
export class UserVerificationModule {}
