import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as NgFormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FormControlValidationErrorsComponent } from './components/form-control-validation-errors/form-control-validation-errors.component';
import { FormControlBaseComponent } from './components/form-control-base/form-control-base.component';
import { FormControlInputComponent } from './components/form-control-input/form-control-input.component';
import { FormControlSelectComponent } from './components/form-control-select/form-control-select.component';
import { FormControlCheckboxComponent } from './components/form-control-checkbox/form-control-checkbox.component';
import { FormControlDropdownComponent } from './components/form-control-dropdown/form-control-dropdown.component';
import { FormServerErrorsComponent } from './components/form-server-errors/form-server-errors.component';
import { FormControlDatepickerComponent } from './components/form-control-datepicker/form-control-datepicker.component';
import { FormControlRadioComponent } from './components/form-control-radio/form-control-radio.component';
import { FormControlTextareaComponent } from './components/form-control-textarea/form-control-textarea.component';
import { FormControlFileComponent } from './components/form-control-file/form-control-file.component';
import { FormControlRadioCheckComponent } from './components/form-control-radio-check/form-control-radio-check.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    FormControlValidationErrorsComponent,
    FormControlBaseComponent,
    FormControlInputComponent,
    FormControlSelectComponent,
    FormControlCheckboxComponent,
    FormControlDropdownComponent,
    FormServerErrorsComponent,
    FormControlDatepickerComponent,
    FormControlRadioComponent,
    FormControlRadioCheckComponent,
    FormControlTextareaComponent,
    FormControlFileComponent,
  ],
  exports: [
    FormControlValidationErrorsComponent,
    FormControlInputComponent,
    FormControlSelectComponent,
    FormControlCheckboxComponent,
    FormControlDropdownComponent,
    FormServerErrorsComponent,
    FormControlDatepickerComponent,
    FormControlRadioComponent,
    FormControlRadioCheckComponent,
    FormControlTextareaComponent,
    FormControlFileComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgFormsModule,
    NgbDropdownModule,
    NgScrollbarModule,
    NgbInputDatepicker,
    SharedModule,
  ],
})
export class FormsModule {}
