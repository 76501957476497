import { ForgotPasswordFormDto } from '../dtos/forgot-password-form.dto';
import { ForgotPasswordFormModel } from '../models/forgot-password-form.model';

export class ForgotPasswordFormDtoCreator {
  public static create(dto: ForgotPasswordFormModel): ForgotPasswordFormDto {
    return {
      email: dto.email,
    };
  }
}
