<div *ngIf="strapiNavbarBanner" class="navbar-banner" [routerLink]="'/dashboard/dashboard'" [fragment]="'referral-button'">
  <div class="h6 pointer-cursor text-uppercase text-white d-flex justify-content-center align-items-center mb-0">
    <i class="far fa-clock fa-lg me-2 me-md-1"></i>
    <p class="text-center" [innerHTML]="strapiNavbarBanner.text | safeHtml"></p>
  </div>
</div>

<nav id="main-navigation" class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand" [href]="getMainLink()">
      <img src="../../../../assets/logos/pawaway.svg" alt="logo" />
    </a>

    <div class="d-none d-lg-flex flex-fill">
      <div class="d-flex flex-fill">
        <a class="nav-link" routerLink="/">
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'layout.navbar.search' | translate }}
        </a>

        <a class="nav-link" target="_blank" href="https://pawaway.com/centrum-pomocy/">
          {{ 'layout.navbar.faq' | translate }}
        </a>

        <a class="nav-link" target="_blank" href="https://pawaway.com/zostan-opiekunem/">
          {{ 'layout.navbar.becomePetsitter' | translate }}
        </a>
      </div>

      <div class="d-none d-lg-flex nav-user" *ngIf="user; else login">
        <div class="pointer-cursor nav-user-container" routerLink="/dashboard/settings">
          <img class="user-picture" [src]="user.pictureUrl" aria-hidden width="48" height="48" />

          <div class="d-flex flex-column">
            <span class="nav-user-head">
              {{ (user.gender === genders.male ? 'layout.navbar.loggedInMale' : 'layout.navbar.loggedInFemale') | translate }}
            </span>

            <span class="nav-user-name">{{ user.firstName }} {{ user.lastName }}</span>
          </div>
        </div>

        <span class="d-none d-lg-inline">
          <app-notifications-dropdown></app-notifications-dropdown>
        </span>

        <button class="btn btn-outline-dark log-out-btn" type="button" (click)="logout()">
          {{ 'layout.navbar.logout' | translate }}
        </button>
      </div>

      <ng-template #login>
        <button routerLink="/auth/login" class="d-none d-lg-block btn btn-neutral" type="button">
          {{ 'layout.navbar.logIn' | translate }}
        </button>
      </ng-template>
    </div>
    <div class="flex d-lg-none">
      <app-notifications-dropdown *ngIf="user"></app-notifications-dropdown>
      <app-offcanvas-menu></app-offcanvas-menu>
    </div>
  </div>
</nav>
