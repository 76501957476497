import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { UserVerificationService } from '../../services/user-verification.service';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { UserModel } from '../../../auth/models/user.model';

@Component({
  selector: 'app-verification-admin-alert',
  templateUrl: './verification-admin-alert.component.html',
  styleUrls: ['./verification-admin-alert.component.scss'],
})
export class VerificationAdminAlertComponent extends SubscriptionStoreComponent implements OnInit {
  public reasonControl: FormControl<string | null>;
  public isReasonControlVisible: boolean;

  private user: UserModel | null;

  constructor(private userVerificationService: UserVerificationService, private authenticationService: AuthenticationService) {
    super();
    this.user = null;
    this.isReasonControlVisible = false;
    this.reasonControl = new FormControl(null);
  }

  public ngOnInit(): void {
    this.initUser();
  }

  public acceptAccount(): void {
    if (!this.user) {
      return;
    }

    this.subscription = this.userVerificationService.acceptAccount(this.user.id).subscribe(() => {
      localStorage.removeItem('IS_ADMIN');
      this.authenticationService.logout();
    });
  }

  public showDeclineAccount(): void {
    this.isReasonControlVisible = true;
  }

  public declineAccount(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.user) {
      return;
    }

    // eslint-disable-next-line no-alert
    const confirmation: boolean = confirm('Czy na pewno chcesz odrzuć to konto?');

    if (!confirmation) {
      return;
    }

    this.subscription = this.userVerificationService.declineAccount(this.user.id, this.reasonControl.value ?? 'reason').subscribe(() => {
      this.isReasonControlVisible = false;
      localStorage.removeItem('IS_ADMIN');
      this.authenticationService.logout();
    });
  }

  private initUser(): void {
    this.subscription = this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      if (!user) {
        return;
      }

      this.user = user;
    });
  }
}
