import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-referrer-redirect',
  templateUrl: './referrer-redirect.component.html',
  styleUrls: ['./referrer-redirect.component.scss'],
})
export class ReferrerRedirectComponent implements OnInit {
  private readonly referralCode: string | null;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.referralCode = this.getReferralCodeFromRouteParams();
  }

  public ngOnInit(): void {
    if (!this.referralCode) {
      this.router.navigate(['/']);
      return;
    }

    localStorage.setItem('referralCode', this.referralCode);
    this.router.navigate(['/auth/register']);
  }

  private getReferralCodeFromRouteParams(): string | null {
    return this.activatedRoute.snapshot.params['referralCode'] ?? null;
  }
}
