import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastrService: ToastrService, private translateService: TranslateService) {}

  public success(message: string, title?: string, override?: Partial<IndividualConfig<any>>): void {
    message = this.translateService.instant(message);

    if (title) {
      title = this.translateService.instant(title);
    }

    this.toastrService.success(message, title, override);
  }

  public error(message: string, title?: string, override?: Partial<IndividualConfig<any>>): void {
    message = this.translateService.instant(message);

    if (title) {
      title = this.translateService.instant(title);
    }

    this.toastrService.error(message, title, override);
  }
}
