import { NgModule } from '@angular/core';

import { EmptyPipe } from './pipes/empty.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';

@NgModule({
  declarations: [EmptyPipe, DateFormatPipe, CamelCasePipe],
  exports: [EmptyPipe, DateFormatPipe, CamelCasePipe],
})
export class PipesModule {}
