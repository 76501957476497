import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserVerificationStatusModel } from '../models/user-verification-status.model';
import { UserVerificationStatusDto } from '../dtos/user-verification-status.dto';
import { UserVerificationStatusCreator } from '../creators/user-verification-status.creator';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserVerificationService {
  public currentUserVerificationStatus: Observable<UserVerificationStatusModel | null>;
  private currentUserVerificationStatusSubject: BehaviorSubject<UserVerificationStatusModel | null>;

  constructor(private httpClient: HttpClient) {
    this.currentUserVerificationStatusSubject = new BehaviorSubject<UserVerificationStatusModel | null>(null);
    this.currentUserVerificationStatus = this.currentUserVerificationStatusSubject.asObservable();
  }

  public initCurrentUserVerificationStatus(): Observable<void> {
    const token: string | null = localStorage.getItem('authorisationToken');

    if (token === null) {
      return of(void 0);
    }

    return this.httpClient.get<UserVerificationStatusDto>(`${environment.apiUrl}/pettsitter-profile/verifications`).pipe(
      switchMap((dto: UserVerificationStatusDto) => {
        const verificationStatus: UserVerificationStatusModel = UserVerificationStatusCreator.create(dto);
        this.setUserVerificationStatus(verificationStatus);

        return of(void 0);
      })
    );
  }

  public sendToVerification(): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/pettsitter-profile/verifications/send-to-verification`, null);
  }

  public acceptAccount(userId: number): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/pettsitter-profile/${userId}/verifications/accept`, null);
  }

  public declineAccount(userId: number, reason: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/pettsitter-profile/${userId}/verifications/decline`, { reason });
  }

  private setUserVerificationStatus(value: UserVerificationStatusModel | null): void {
    this.currentUserVerificationStatusSubject.next(value);
  }
}
