import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CURRENT_LANGUAGE_KEY } from '../services/current-language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'JSONP') {
      return next.handle(request);
    }

    const currentLanguage: string | null = localStorage.getItem(CURRENT_LANGUAGE_KEY);

    if (currentLanguage) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': currentLanguage,
        },
      });
    }

    return next.handle(request);
  }
}
