<ng-container>
  <div class="navbar-banner">
    <span class="inactive">Twoje konto jest nieaktywne!</span>

    <ng-container *ngIf="isFilledPetsitterProfileVisible">
      <p>
        <span>
          Krok
          <i class="fa-solid fa-circle-1"></i>
          z
          <i class="fa-solid fa-circle-5"></i>
          {{ 'userVerification.userVerificationAlert.petsitterProfile' | translate }}
        </span>
        <a class="alert-button" routerLink="/dashboard/profile/basic">
          {{ 'userVerification.userVerificationAlert.petsitterProfileLink' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="isFilledGalleryVisible">
      <p>
        <span>
          Krok
          <i class="fa-solid fa-circle-2"></i>
          z
          <i class="fa-solid fa-circle-5"></i>
          {{ 'userVerification.userVerificationAlert.gallery' | translate }}
        </span>
        <a class="alert-button" routerLink="/dashboard/profile/gallery">
          {{ 'userVerification.userVerificationAlert.galleryLink' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="isFilledServiceVisible">
      <p>
        <span>
          Krok
          <i class="fa-solid fa-circle-3"></i>
          z
          <i class="fa-solid fa-circle-5"></i>
          {{ 'userVerification.userVerificationAlert.service' | translate }}
        </span>
        <a class="alert-button" routerLink="/dashboard/services/services">
          {{ 'userVerification.userVerificationAlert.serviceLink' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="isFilledMyPlaceVisible">
      <p>
        <span>
          Krok
          <i class="fa-solid fa-circle-4"></i>
          z
          <i class="fa-solid fa-circle-5"></i>
          {{ 'userVerification.userVerificationAlert.myPlace' | translate }}
        </span>
        <a class="alert-button" routerLink="/dashboard/profile/my-place">
          {{ 'userVerification.userVerificationAlert.myPlaceLink' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="isFilledStripeVisible">
      <p>
        <span>
          Krok
          <i class="fa-solid fa-circle-5"></i>
          z
          <i class="fa-solid fa-circle-5"></i>
          {{ 'userVerification.userVerificationAlert.stripe' | translate }}
        </span>
        <a class="alert-button" routerLink="/dashboard/settings/payments">
          {{ 'userVerification.userVerificationAlert.stripeLink' | translate }}
        </a>
      </p>
    </ng-container>

    <ng-container *ngIf="isSendToVerificationVisible">
      <p>
        <span>
          {{ 'userVerification.userVerificationAlert.sendToVerification' | translate }}
        </span>
        <a class="alert-button" (click)="sendToVerification()">
          {{ 'userVerification.userVerificationAlert.sendToVerificationLink' | translate }}
        </a>
      </p>
    </ng-container>
  </div>
</ng-container>
