import { RegisterFormModel } from '../models/register-form.model';
import { RegisterFormDto } from '../dtos/register-form.dto';

export class RegisterFormCreator {
  public static create(model: RegisterFormModel): RegisterFormDto {
    return {
      business_type: model.companyType,
      hotel_name: model.companyName,
      nip: model.nip,
      first_name: model.firstName,
      last_name: model.lastName,
      phone_number: `${model.prefix}${model.phoneNumber}`,
      email: model.email,
      password: model.password,
      password_confirmation: model.confirmationPassword,
      acceptet_privacy_regulations: model.privacyRegulations,
      acceptet_merketing: model.marketingRegulations,
    };
  }
}
