import { Component, OnInit } from '@angular/core';

import { SubscriptionStoreComponent } from 'src/app/shared/components/subscription-store/subscription-store.component';
import { StrapiService } from 'src/app/strapi/services/strapi.service';
import { StrapiResponseModel } from 'src/app/strapi/models/strapi-response.model';
import { StrapiDataModel } from 'src/app/strapi/models/strapi-data.model';

import { StrapiFooterModel } from '../../models/strapi-footer.model';
import { MainRedirect } from '../../../shared/services/main-redirect.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends SubscriptionStoreComponent implements OnInit {
  public strapiFooterDescription: StrapiFooterModel | null;

  constructor(private strapiService: StrapiService, private mainRedirect: MainRedirect) {
    super();

    this.strapiFooterDescription = null;
  }

  public ngOnInit(): void {
    this.initDescription();
  }

  public getMainLink(): string {
    return this.mainRedirect.getMainPageString();
  }

  private initDescription(): void {
    this.subscription = this.strapiService
      .getSingle<StrapiFooterModel>('/footer', null, {
        populate: [
          'subFooterText',
          'seeMore',
          'aboutCompany',
          'copyright',
          'pawcover',
          'logo',
          'socialMediaLinks.*',
          'columnOneLinks.*',
          'columnTwoLinks.*',
          'columnThreeLinks.*',
          'columnOneTitle',
          'columnTwoTitle',
          'columnThreeTitle',
        ],
      })
      .subscribe((strapiResponse: StrapiResponseModel<StrapiDataModel<StrapiFooterModel>>) => {
        this.strapiFooterDescription = strapiResponse.data.attributes;
      });
  }
}
