import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [NotificationsDropdownComponent],
  imports: [CommonModule, TranslateModule, NgbDropdownModule, PipesModule],
  exports: [NotificationsDropdownComponent],
})
export class SystemNotificationsModule {}
