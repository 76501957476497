import { SystemNotificationDto } from '../dtos/system-notification.dto';
import { SystemNotificationModel } from '../models/system-notification.model';

export class SystemNotificationCreator {
  public static create(dto: SystemNotificationDto): SystemNotificationModel {
    return {
      id: dto.id,
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      readAt: dto.read_at,
      image: dto.image,
      imageUrl: dto.image_url,
      title: dto.title,
      type: dto.type,
      data: dto.data,
      orderId: dto.order_id,
      userId: dto.user_id,
    };
  }
}
