<div class="navbar-banner">
  <p>
    {{ reason }}

    <a class="pointer-cursor" (click)="sendToVerification()">
      {{ 'userVerification.verificationFailedAlert.sendToVerification' | translate }}
    </a>
    .
  </p>
</div>
