import { Pipe, PipeTransform } from '@angular/core';

import { Moment } from 'moment/moment';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  public transform(value: string | Date | Moment | null | undefined, ...args: Array<string>): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    return moment(value).format(args[0] ?? 'dddd DD MMMM YYYY');
  }
}
