export enum CompletionStatusEnum {
  newUser = 'new_user',
  confirmation = 'confirmation',
  approvals = 'approvals',
  about = 'about',
  address = 'address',
  declaration = 'declarations',
  why = 'why',
  marketingChannel = 'marketing_channel',
  picture = 'picture',
  finished = 'FINISHED',
}
