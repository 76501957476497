import { Component, OnInit } from '@angular/core';

import { UserModel } from '../../../auth/models/user.model';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { DashboardNavbarItemModel } from '../../models/dashboard-navbar-item.model';
import { DashboardNavbarService } from '../../services/dashboard-navbar.service';
import { VerificationStatusEnum } from '../../../auth/enums/verification-status.enum';
import { CompanyTypeEnum } from '../../../auth/enums/company-type.enum';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent extends SubscriptionStoreComponent implements OnInit {
  public user: UserModel | null;
  public dashboardNavbarItems: Array<DashboardNavbarItemModel>;
  public verificationStatus: typeof VerificationStatusEnum;
  public companyType: typeof CompanyTypeEnum;
  public isAdmin: boolean;

  constructor(private authenticationService: AuthenticationService, private dashboardNavbarService: DashboardNavbarService) {
    super();

    this.user = null;
    this.dashboardNavbarItems = [];
    this.verificationStatus = VerificationStatusEnum;
    this.companyType = CompanyTypeEnum;
    this.isAdmin = localStorage.getItem('IS_ADMIN') === 'VERY_SECURE';
  }

  public ngOnInit(): void {
    this.initUser();
    this.initDashboardNavbarItems();
  }

  private initUser(): void {
    this.subscription = this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      if (!user) {
        return;
      }

      this.user = user;
    });
  }

  private initDashboardNavbarItems(): void {
    this.subscription = this.dashboardNavbarService.currentDashboardNavbarItems.subscribe(
      (dashboardNavbarItems: Array<DashboardNavbarItemModel> | null) => {
        if (!dashboardNavbarItems) {
          return;
        }

        this.dashboardNavbarItems = dashboardNavbarItems;
      }
    );
  }
}
