import { ResetPasswordFormModel } from '../models/reset-password-form.model';
import { ResetPasswordFormDto } from '../dtos/reset-password-form.dto';

export class ResetPasswordFormDtoCreator {
  public static create(model: ResetPasswordFormModel): ResetPasswordFormDto {
    return {
      reset_password_guid: model.guid,
      password: model.password,
      password_confirmation: model.passwordConfirmation,
    };
  }
}
