import { PaginationDataModel } from '../models/pagination-data.model';
import { PaginationDataDto } from '../dtos/pagination-data.dto';

export class PaginationDataDtoCreator {
  public static create(model: PaginationDataModel): PaginationDataDto {
    return {
      page: model.page,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
    };
  }
}
