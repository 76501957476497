import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainRedirect {
  constructor() {
    // no empty fn
  }

  public getMainPageString(): string {
    return environment.production ? 'https://pawaway.com' : '/';
  }
}
