import { LoginFormModel } from '../models/login-form.model';
import { LoginFormDto } from '../dtos/login-form.dto';

export class LoginFormDtoCreator {
  public static create(model: LoginFormModel): LoginFormDto {
    return {
      email: model.email,
      password: model.password,
    };
  }
}
