import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../auth/services/authentication.service';
import { UserModel } from '../../auth/models/user.model';
import { CompletionStatusEnum } from '../enums/completion-status.enum';

@Injectable({
  providedIn: 'root',
})
export class AccountCreationNotCompletedGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.currentUser.pipe(
      tap((user: UserModel | null) => {
        if (!user) {
          return;
        }

        if (user.completionStatus === CompletionStatusEnum.finished) {
          this.router.navigate(['/dashboard']);
        }
      }),
      map((user: UserModel | null) => {
        if (!user) {
          return false;
        }

        if (user.completionStatus === CompletionStatusEnum.finished) {
          return false;
        }

        return true;
      })
    );
  }
}
