import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SettingsModel } from '../models/settings.model';
import { SettingsDto } from '../dtos/settings.dto';
import { environment } from '../../../environments/environment';
import { SettingsCreator } from '../creators/settings.creator';

@Injectable({
  providedIn: 'root',
})
export class CurrentSettingsService {
  private currentSettings: BehaviorSubject<SettingsModel | null>;

  constructor(private httpClient: HttpClient) {
    this.currentSettings = new BehaviorSubject<SettingsModel | null>(null);
  }

  public initSettings(): Observable<any> {
    return this.updateCurrentSettings();
  }

  public getCurrentSettings(): Observable<SettingsModel | null> {
    return this.currentSettings.asObservable();
  }

  public getSettings(): Observable<SettingsModel> {
    return this.httpClient.get<SettingsDto>(`${environment.apiUrl}/system-settings`).pipe(
      map((dto: SettingsDto) => {
        return SettingsCreator.create(dto);
      })
    );
  }

  public updateCurrentSettings(): Observable<void> {
    return this.getSettings().pipe(
      tap((currentSettings: SettingsModel) => {
        this.setCurrentSettings(currentSettings);
      }),
      map(() => void 0)
    );
  }

  private setCurrentSettings(currentSettings: SettingsModel): void {
    this.currentSettings.next(currentSettings);
  }
}
