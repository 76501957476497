import { Component, Input } from '@angular/core';

import { switchMap } from 'rxjs';

import { UserVerificationService } from '../../services/user-verification.service';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-verification-failed-alert[reason]',
  templateUrl: './verification-failed-alert.component.html',
  styleUrls: ['./verification-failed-alert.component.scss'],
})
export class VerificationFailedAlertComponent extends SubscriptionStoreComponent {
  @Input() public reason!: string;

  constructor(
    private verificationService: UserVerificationService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService
  ) {
    super();
  }

  public sendToVerification(): void {
    this.subscription = this.verificationService
      .sendToVerification()
      .pipe(
        switchMap(() => {
          return this.authenticationService.initCurrentUser();
        })
      )
      .subscribe({
        error: () => {
          this.toastService.error('userVerification.verificationFailedAlert.verificationFailedAlert');
        },
      });
  }
}
