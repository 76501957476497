import { Component, OnInit } from '@angular/core';

import { StrapiService } from 'src/app/strapi/services/strapi.service';
import { StrapiDataModel } from 'src/app/strapi/models/strapi-data.model';
import { StrapiResponseModel } from 'src/app/strapi/models/strapi-response.model';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { DashboardNavbarItemModel } from '../../models/dashboard-navbar-item.model';
import { DashboardNavbarService } from '../../services/dashboard-navbar.service';
import { UserModel } from '../../../auth/models/user.model';
import { GendersEnum } from '../../../auth/enums/genders.enum';
import { StrapiNavbarModel } from '../../models/strapi-navbar.model';
import { CompanyTypeEnum } from '../../../auth/enums/company-type.enum';
import { MainRedirect } from '../../../shared/services/main-redirect.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends SubscriptionStoreComponent implements OnInit {
  public user: UserModel | null;
  public isOwner: boolean;
  public genders: typeof GendersEnum;
  public strapiNavbarBanner: StrapiNavbarModel | null;
  public dashboardNavbarItems: Array<DashboardNavbarItemModel>;

  constructor(
    private authenticationService: AuthenticationService,
    private strapiService: StrapiService,
    private dashboardNavbarService: DashboardNavbarService,
    private mainRedirect: MainRedirect
  ) {
    super();

    this.user = null;
    this.genders = GendersEnum;
    this.strapiNavbarBanner = null;
    this.dashboardNavbarItems = [];
    this.isOwner = false;
  }

  public ngOnInit(): void {
    this.initUser();
    this.initBaner();
    this.initDashboardNavbarItems();
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public initUser(): void {
    this.subscription = this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      this.user = user;
      this.isOwner = user?.companyType === CompanyTypeEnum.owner;
    });
  }

  public getMainLink(): string {
    return this.mainRedirect.getMainPageString();
  }

  private initBaner(): void {
    this.subscription = this.strapiService
      .getSingle<StrapiNavbarModel>('/navbar', null, {
        populate: ['icon', 'text'],
      })
      .subscribe((strapiResponse: StrapiResponseModel<StrapiDataModel<StrapiNavbarModel>>) => {
        this.strapiNavbarBanner = strapiResponse.data.attributes;
      });
  }

  private initDashboardNavbarItems(): void {
    this.subscription = this.dashboardNavbarService.currentDashboardNavbarItems.subscribe(
      (dashboardNavbarItems: Array<DashboardNavbarItemModel> | null) => {
        if (!dashboardNavbarItems) {
          return;
        }

        this.dashboardNavbarItems = dashboardNavbarItems;
      }
    );
  }
}
