import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { FormControlBaseComponent } from '../form-control-base/form-control-base.component';

@Component({
  selector: 'app-form-control-textarea',
  templateUrl: './form-control-textarea.component.html',
  styleUrls: ['./form-control-textarea.component.scss'],
})
export class FormControlTextareaComponent extends FormControlBaseComponent<string> implements OnInit, OnDestroy {
  @Input() public placeholder: string;
  @Input() public extraClass: string;
  @Input() public maxLength: number | null;
  @Input() public showCounter: boolean;
  @Input() public autoResize: boolean;
  @Input() public description: string | null;

  @Output() public inputBlur: EventEmitter<string>;

  public counter: number;

  private subscription: Subscription;

  constructor(@Optional() ngControl: NgControl) {
    super(ngControl);

    this.placeholder = '';
    this.description = null;
    this.extraClass = '';
    this.maxLength = null;
    this.inputBlur = new EventEmitter<string>();
    this.showCounter = false;
    this.autoResize = false;
    this.counter = 0;
    this.subscription = new Subscription();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.initCounter();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public inputChange(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  public onBlur(): void {
    this.emitInputBlurEvent();
    this.onTouched();
  }

  private emitInputBlurEvent(): void {
    this.inputBlur.emit(this.control?.value as string);
  }

  private initCounter(): void {
    if (!this.showCounter || !this.ngControl.control) {
      return;
    }

    const controlValue: string | null = this.ngControl.control.value;
    this.counter = !controlValue ? 0 : controlValue.length;

    const subscription: Subscription = this.ngControl.control.valueChanges.subscribe((value: string) => {
      if (!value) {
        this.counter = 0;
        return;
      }

      this.counter = value.length;
    });

    this.subscription.add(subscription);
  }
}
