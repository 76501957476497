import { Component, OnInit, TemplateRef } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';

import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { DashboardNavbarItemModel } from '../../models/dashboard-navbar-item.model';
import { DashboardNavbarService } from '../../services/dashboard-navbar.service';
import { UserModel } from '../../../auth/models/user.model';
import { GendersEnum } from '../../../auth/enums/genders.enum';
import { StrapiNavbarModel } from '../../models/strapi-navbar.model';
import { CompanyTypeEnum } from '../../../auth/enums/company-type.enum';

@Component({
  selector: 'app-offcanvas-menu',
  templateUrl: './offcanvas-menu.component.html',
  styleUrls: ['./offcanvas-menu.component.scss'],
})
export class OffcanvasMenuComponent extends SubscriptionStoreComponent implements OnInit {
  public user: UserModel | null;
  public genders: typeof GendersEnum;
  public strapiNavbarBanner: StrapiNavbarModel | null;
  public dashboardNavbarItems: Array<DashboardNavbarItemModel>;
  public isUser: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardNavbarService: DashboardNavbarService,
    private offcanvasService: NgbOffcanvas,
    private router: Router
  ) {
    super();

    this.user = null;
    this.isUser = true;
    this.genders = GendersEnum;
    this.strapiNavbarBanner = null;
    this.dashboardNavbarItems = [];
  }

  public ngOnInit(): void {
    this.initUser();
    this.initDashboardNavbarItems();
    this.closeOnNavigation();
  }

  public open(content: TemplateRef<any>): void {
    this.offcanvasService.open(content, { position: 'end' });
  }

  public closeOnNavigation(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.offcanvasService.dismiss();
      }
    });
  }

  public logout(): void {
    this.authenticationService.logout();
    this.offcanvasService.dismiss();
  }

  public initUser(): void {
    this.subscription = this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      this.user = user;
      this.isUser = !!user && user.companyType === CompanyTypeEnum.owner;
    });
  }

  private initDashboardNavbarItems(): void {
    this.subscription = this.dashboardNavbarService.currentDashboardNavbarItems.subscribe(
      (dashboardNavbarItems: Array<DashboardNavbarItemModel> | null) => {
        if (!dashboardNavbarItems) {
          return;
        }

        this.dashboardNavbarItems = dashboardNavbarItems;
      }
    );
  }
}
