import { Component, OnInit } from '@angular/core';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { filter } from 'rxjs';
import { NgxHotjarService } from 'ngx-hotjar';

import { SubscriptionStoreComponent } from './shared/components/subscription-store/subscription-store.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriptionStoreComponent implements OnInit {
  constructor(private router: Router, private hotjarServcie: NgxHotjarService, private analytics: AngularFireAnalytics) {
    super();
  }

  public ngOnInit(): void {
    this.initHotjarStateChange();
  }

  public initHotjarStateChange(): void {
    this.subscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.hotjarServcie.trigger(event.urlAfterRedirects);

          this.analytics.logEvent('page_view', {
            page_title: event.urlAfterRedirects,
          });
        }
      });
  }
}
