<div class="mb-3">
  <div class="d-flex flex-column">
    <label [for]="uid" *ngIf="label" class="form-label" [class.is-required]="isRequired$ | async">
      {{ label | translate }}
    </label>

    <small *ngIf="description" class="form-text text-muted description">{{ description }}</small>
  </div>

  <textarea
    class="form-control"
    [class]="extraClass"
    [id]="uid"
    [placeholder]="placeholder | translate"
    [disabled]="isDisabled"
    [maxlength]="maxLength"
    [ngModel]="control?.value"
    (ngModelChange)="inputChange($event)"
    (blur)="onBlur()"
    [class.is-invalid]="canShowErrors && control?.invalid && control?.touched"
    [appTextareaAutoresize]="autoResize"
  ></textarea>

  <div class="form-text" *ngIf="showCounter">{{ counter }}/{{ maxLength }}</div>

  <app-form-control-validation-errors
    *ngIf="control?.invalid && control?.touched && canShowErrors"
    [errors]="control?.errors"
  ></app-form-control-validation-errors>
</div>
