import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class StrapiInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf(environment.strapi.url) > -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${environment.strapi.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
