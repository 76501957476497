import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NgbRating } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionStoreComponent } from './components/subscription-store/subscription-store.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { SwiperDirective } from './directives/swiper.directive';
import { RatingComponent } from './components/rating/rating.component';
import { StarsComponent } from './components/stars/stars.component';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';

@NgModule({
  declarations: [
    SubscriptionStoreComponent,
    LoaderComponent,
    NoResultsComponent,
    InfoCardComponent,
    SwiperDirective,
    RatingComponent,
    StarsComponent,
    TextareaAutoresizeDirective,
  ],
  imports: [CommonModule, TranslateModule, NgbRating],
  exports: [
    SubscriptionStoreComponent,
    LoaderComponent,
    NoResultsComponent,
    InfoCardComponent,
    SwiperDirective,
    RatingComponent,
    StarsComponent,
    TextareaAutoresizeDirective,
  ],
})
export class SharedModule {}
