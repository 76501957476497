import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { UserVerificationService } from '../../services/user-verification.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss'],
})
export class VerifyUserComponent extends SubscriptionStoreComponent implements OnInit {
  private readonly token: string | null;

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userVerificationService: UserVerificationService
  ) {
    super();

    this.token = this.getTokenFromRouteParams();
  }

  public ngOnInit(): void {
    this.authorizeWithAdminFlag();
  }

  private getTokenFromRouteParams(): string | null {
    return this.activatedRoute.snapshot.params['token'] ?? null;
  }

  private authorizeWithAdminFlag(): void {
    if (!this.token) {
      return;
    }

    this.authenticationService.setAccessToken(this.token);
    localStorage.setItem('IS_ADMIN', 'VERY_SECURE');
    this.authenticationService.initCurrentUser().subscribe(() => {
      this.router.navigate(['/dashboard']);
      this.userVerificationService.initCurrentUserVerificationStatus().subscribe();
    });
  }
}
