<app-navbar></app-navbar>

<div>
  <div class="petsitter-navbar">
    <div class="container">
      <ul class="navbar-nav petsitter-nav">
        <li class="nav-item" *ngFor="let item of dashboardNavbarItems">
          <a class="nav-link" [routerLink]="item.path" [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="active">
            <img class="mb-2" [src]="item.icon" alt="" />

            {{ item.title | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ng-container *ngIf="user && user.companyType !== companyType.owner">
    <div class="verification-banner">
      <app-user-verification-alert *ngIf="user.verificationStatus === null"></app-user-verification-alert>

      <app-waiting-for-verification-alert
        class="verification-banner"
        *ngIf="user.verificationStatus === verificationStatus.waiting"
      ></app-waiting-for-verification-alert>

      <app-verification-failed-alert
        class="verification-banner"
        [reason]="user.verificationStatusReason ?? ''"
        *ngIf="user.verificationStatus === verificationStatus.failed"
      ></app-verification-failed-alert>
    </div>
  </ng-container>

  <app-verification-admin-alert class="verification-banner" *ngIf="isAdmin"></app-verification-admin-alert>

  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
