<div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block" placement="bottom-end">
  <button type="button" class="btn btn-outline-dark" id="notifications-dropdown" ngbDropdownToggle>
    <i class="fa-regular fa-bell" [class]="hasNotification ? 'active' : ''"></i>
  </button>
  <div ngbDropdownMenu aria-labelledby="notifications-dropdown" class="dropdown-menu">
    <p class="notifications-title">
      {{ 'layout.notificationsDropdown.title' | translate }}
    </p>
    <div class="scroll-cont">
      <div *ngFor="let systemNotification of systemNotifications" (click)="notificationAction(systemNotification)" class="notification">
        <img [src]="systemNotification.imageUrl" alt="" class="notification-img" />

        <div class="notification-body">
          <p class="notification-title">{{ user?.companyType === companyType.owner ? 'Opiekun' : 'Właściciel' }}</p>

          <p class="company-name">{{ systemNotification.title }}</p>

          <ng-container [ngSwitch]="systemNotification.type">
            <p *ngSwitchCase="systemNotificationType.message" class="text-alternative-green">
              <strong>
                <i class="fas fa-check-circle"></i>
                Wyslal wiadomosc
              </strong>
            </p>

            <p *ngSwitchCase="systemNotificationType.orderAccepted" class="text-alternative-green">
              <strong>
                <i class="fas fa-check-circle"></i>
                Zaakceptował zapytanie
              </strong>
            </p>

            <p *ngSwitchCase="systemNotificationType.newOrder" class="text-alternative-green">
              <strong>
                <i class="fas fa-check-circle"></i>
                Wyslal zapytanie
              </strong>
            </p>

            <p *ngSwitchCase="systemNotificationType.orderPaid" class="text-alternative-green">
              <strong>
                <i class="fas fa-check-circle"></i>
                Oplacil zamowienie
              </strong>
            </p>

            <p *ngSwitchCase="systemNotificationType.orderCancelled" class="text-alternative-green">
              <strong>
                <i class="fas fa-check-circle"></i>
                Anulowal zamowienie
              </strong>
            </p>
          </ng-container>

          <p class="time">{{ systemNotification.createdAt | dateFormat : 'DD.MM.YYYY HH:mm' }}</p>
        </div>

        <button (click)="deleteNotification($event, systemNotification.id)" type="button" class="invisible-btn">
          <i class="fa-light fa-xmark"></i>
        </button>
      </div>
    </div>
  </div>
</div>
