<ng-container *ngFor="let error of errors | keyvalue">
  <div class="invalid-feedback d-block">
    <ng-container [ngSwitch]="error.key">
      <ng-container *ngSwitchCase="'minlength'">
        {{ 'validators.' + error.key | translate : { value: error.value.requiredLength } }}
      </ng-container>

      <ng-container *ngSwitchCase="'max'">
        {{ 'validators.' + error.key | translate : { value: error.value.max } }}
      </ng-container>

      <ng-container *ngSwitchCase="'min'">
        {{ 'validators.' + error.key | translate : { value: error.value.min } }}
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{ 'validators.' + error.key | translate }}
      </ng-container>
    </ng-container>
  </div>
</ng-container>
