import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserVerificationModule } from '../user-verification/user-verification.module';
import { StrapiModule } from '../strapi/strapi.module';
import { SystemNotificationsModule } from '../system-notifications/system-notifications.module';
import { OffcanvasMenuComponent } from './components/offcanvas-menu/offcanvas-menu.component';

@NgModule({
  declarations: [DashboardLayoutComponent, DefaultLayoutComponent, NavbarComponent, OffcanvasMenuComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    UserVerificationModule,
    StrapiModule,
    SystemNotificationsModule,
  ],
})
export class LayoutModule {}
