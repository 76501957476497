import { LoginDto } from '../dtos/login.dto';
import { LoginModel } from '../models/login.model';
import { UserCreator } from './user.creator';

export class LoginCreator {
  public static create(dto: LoginDto): LoginModel {
    return {
      accessToken: dto.access_token,
      user: UserCreator.create(dto.user),
    };
  }
}
