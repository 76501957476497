import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserModel } from '../models/user.model';
import { UserDto } from '../dtos/user.dto';
import { UserCreator } from '../creators/user.creator';
import { LoginFormModel } from '../models/login-form.model';
import { LoginDto } from '../dtos/login.dto';
import { LoginFormDto } from '../dtos/login-form.dto';
import { LoginFormDtoCreator } from '../creators/login-form-dto.creator';
import { LoginModel } from '../models/login.model';
import { LoginCreator } from '../creators/login.creator';
import { RegisterFormModel } from '../models/register-form.model';
import { RegisterFormCreator } from '../creators/register-form.creator';
import { RegisterFormDto } from '../dtos/register-form.dto';
import { ConfirmEmailDto } from '../dtos/confirm-email.dto';
import { ConfirmEmailDtoCreator } from '../creators/confirm-email-dto.creator';
import { ForgotPasswordFormModel } from '../models/forgot-password-form.model';
import { ForgotPasswordFormDto } from '../dtos/forgot-password-form.dto';
import { ForgotPasswordFormDtoCreator } from '../creators/forgot-password-form-dto.creator';
import { ResetPasswordFormDtoCreator } from '../creators/reset-password-form-dto.creator';
import { ResetPasswordFormDto } from '../dtos/reset-password-form.dto';
import { ResetPasswordFormModel } from '../models/reset-password-form.model';
import { RegisterUserFormCreator } from '../creators/register-user-form.creator';
import { RegisterUserFormDto } from '../dtos/register-user-form.dto';
import { RegisterUserFormModel } from '../models/register-user-form.model';
import { UserVerificationService } from '../../user-verification/services/user-verification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public currentUser: Observable<UserModel | null>;
  private currentUserSubject: BehaviorSubject<UserModel | null>;

  constructor(private http: HttpClient, private router: Router, private userVerificationService: UserVerificationService) {
    this.currentUserSubject = new BehaviorSubject<UserModel | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public initCurrentUser(): Observable<void> {
    const token: string | null = localStorage.getItem('authorisationToken');

    if (token === null) {
      return of(void 0);
    }

    return this.http.get<UserDto>(`${environment.apiUrl}/auth/me`).pipe(
      switchMap((userDto: UserDto) => {
        const user: UserModel = UserCreator.create(userDto);
        this.setUser(user);

        return of(void 0);
      })
    );
  }

  public login(loginForm: LoginFormModel): Observable<boolean> {
    const dto: LoginFormDto = LoginFormDtoCreator.create(loginForm);

    return this.http.post<LoginDto>(`${environment.apiUrl}/auth/login`, dto).pipe(
      map((dto: LoginDto) => {
        const login: LoginModel = LoginCreator.create(dto);

        this.setAccessToken(login.accessToken);
        this.setUser(login.user);
        this.userVerificationService.initCurrentUserVerificationStatus().subscribe();

        return true;
      })
    );
  }

  public register(registerForm: RegisterFormModel): Observable<void> {
    const dto: RegisterFormDto = RegisterFormCreator.create(registerForm);

    return this.http.post<void>(`${environment.apiUrl}/auth/register`, dto);
  }

  public registerUser(registerForm: RegisterUserFormModel): Observable<void> {
    const dto: RegisterUserFormDto = RegisterUserFormCreator.create(registerForm);

    return this.http.post<void>(`${environment.apiUrl}/auth/register`, dto);
  }

  public forgotPassword(forgotPasswordForm: ForgotPasswordFormModel): Observable<void> {
    const dto: ForgotPasswordFormDto = ForgotPasswordFormDtoCreator.create(forgotPasswordForm);

    return this.http.post<void>(`${environment.apiUrl}/auth/forgot-password`, dto);
  }

  public resetPassword(resetPasswordForm: ResetPasswordFormModel): Observable<void> {
    const dto: ResetPasswordFormDto = ResetPasswordFormDtoCreator.create(resetPasswordForm);

    return this.http.post<void>(`${environment.apiUrl}/auth/reset-password`, dto);
  }

  public confirmEmail(registerGuid: string): Observable<void> {
    const dto: ConfirmEmailDto = ConfirmEmailDtoCreator.create(registerGuid);

    return this.http.post<void>(`${environment.apiUrl}/auth/confirmation`, dto);
  }

  public resetUserAndRedirect(): void {
    this.setUser(null);
    localStorage.removeItem('authorisationToken');
    localStorage.removeItem('IS_ADMIN');
    this.router.navigate(['/']);
  }

  public logout(): void {
    this.http.post(`${environment.apiUrl}/auth/logout`, null).subscribe({
      next: () => {
        this.resetUserAndRedirect();
      },
      error: () => {
        this.resetUserAndRedirect();
      },
    });
  }

  public setAccessToken(token: string): void {
    localStorage.setItem('authorisationToken', token);
  }

  private setUser(value: UserModel | null): void {
    this.currentUserSubject.next(value);
  }
}
