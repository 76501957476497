<button class="navbar-toggler" type="button" aria-label="Toggle navigation" (click)="open(content)">
  <span class="navbar-toggler-icon"></span>
</button>
<ng-template #content let-offcanvas>
  <div class="offcanvas-header">
    <span></span>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()"></button>
  </div>

  <div class="offcanvas-body">
    <div class="d-flex flex-column">
      <div class="nav-user" *ngIf="user; else login">
        <div class="pointer-cursor nav-user-container" routerLink="/dashboard/settings">
          <img class="user-picture" [src]="user.pictureUrl" aria-hidden width="48" height="48" />

          <div class="d-flex flex-column">
            <span class="nav-user-head">
              {{ (user.gender === genders.male ? 'layout.navbar.loggedInMale' : 'layout.navbar.loggedInFemale') | translate }}
            </span>

            <span class="nav-user-name">{{ user.firstName }} {{ user.lastName }}</span>
          </div>
        </div>

        <span class="d-none d-lg-inline">
          <app-notifications-dropdown></app-notifications-dropdown>
        </span>

        <button class="btn btn-outline-dark log-out-btn" type="button" (click)="logout()">
          <i class="fas fa-sign-out"></i>
        </button>
      </div>
    </div>

    <ng-template #login>
      <button routerLink="/auth/login" class="btn btn-neutral text-uppercase" type="button">
        {{ 'layout.navbar.logIn' | translate }}
      </button>
    </ng-template>

    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="btn-nav-link nav-link" routerLink="/">
          <i class="dashboard-icon fa-solid fa-magnifying-glass"></i>
          {{ 'layout.navbar.search' | translate }}
        </a>
      </li>

      <ng-container *ngIf="user">
        <li class="nav-item dashboard-item" *ngFor="let item of dashboardNavbarItems">
          <a class="nav-link link" [routerLink]="item.path" [routerLinkActiveOptions]="{ exact: false }" routerLinkActive="active">
            <img class="dashboard-icon" [src]="item.icon" aria-hidden height="21" />
            {{ item.title | translate }}
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="!user || isUser">
        <li class="nav-item">
          <a class="nav-link btn-nav-link" target="_blank" href="https://pawaway.com/zostan-opiekunem/">
            <i class="dashboard-icon fa fa-dog" aria-hidden></i>
            {{ 'layout.navbar.becomePetsitter' | translate }}
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="!user">
        <li class="nav-item">
          <a class="nav-link link" target="_blank" href="https://pawaway.com/#uslugi">
            <i class="dashboard-icon fa-regular fa-hard-drive" aria-hidden></i>
            {{ 'layout.navbar.services' | translate }}
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link link" href="/auth/register">
            <i class="dashboard-icon fa-solid fa-building-circle-check" aria-hidden></i>
            {{ 'layout.navbar.register' | translate }}
          </a>
        </li>
      </ng-container>

      <li class="nav-item">
        <a class="nav-link link" target="_blank" href="https://pawaway.com/centrum-pomocy/">
          <i class="dashboard-icon fa-regular fa-circle-question" aria-hidden></i>
          {{ 'layout.navbar.faq' | translate }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>
