import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StrapiMediaUrlPipe } from './pipes/strapi-media-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [StrapiMediaUrlPipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [StrapiMediaUrlPipe, SafeHtmlPipe],
})
export class StrapiModule {}
