import { UserVerificationStatusDto } from '../dtos/user-verification-status.dto';
import { UserVerificationStatusModel } from '../models/user-verification-status.model';

export class UserVerificationStatusCreator {
  public static create(dto: UserVerificationStatusDto): UserVerificationStatusModel {
    return {
      filledPetsitterProfile: dto.filled_petsitter_profile,
      filledGallery: dto.filled_gallery,
      filledService: dto.filled_service,
      filledStripe: dto.filled_stripe,
      filledMyPlace: dto.filled_my_place,
    };
  }
}
