import { Component, OnInit, ViewChild } from '@angular/core';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { SystemNotificationsService } from '../../services/system-notifications.service';
import { SystemNotificationModel } from '../../models/system-notification.model';
import { SystemNotificationTypeEnum } from '../../enums/system-notification-type.enum';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { UserModel } from '../../../auth/models/user.model';
import { CompanyTypeEnum } from '../../../auth/enums/company-type.enum';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent extends SubscriptionStoreComponent implements OnInit {
  @ViewChild(NgbDropdown) private readonly myDrop: NgbDropdown | null;

  public hasNotification: boolean;
  public systemNotifications: Array<SystemNotificationModel>;
  public systemNotificationType: typeof SystemNotificationTypeEnum;
  public user: UserModel | null;
  public companyType: typeof CompanyTypeEnum;

  constructor(
    private systemNotificationsService: SystemNotificationsService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    super();

    this.hasNotification = false;
    this.systemNotifications = [];
    this.systemNotificationType = SystemNotificationTypeEnum;
    this.myDrop = null;
    this.user = null;
    this.companyType = CompanyTypeEnum;
  }

  public ngOnInit(): void {
    this.initSystemNotifications();
    this.initRouteChangeListener();
    this.initUser();
  }

  public notificationAction(systemNotification: SystemNotificationModel): void {
    this.subscription = this.systemNotificationsService.getSystemNotification(systemNotification.id).subscribe();

    this.router.navigate(['/order', systemNotification.orderId, 'pending']).then(() => {
      if (!this.myDrop) {
        return;
      }

      this.myDrop.close();
    });
  }

  public deleteNotification(event: Event, notificationId: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.subscription = this.systemNotificationsService.deleteNotification(notificationId).subscribe(() => {
      this.initSystemNotifications();
    });
  }

  private initRouteChangeListener(): void {
    this.subscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
      this.initSystemNotifications();
    });
  }

  private initSystemNotifications(): void {
    this.subscription = this.systemNotificationsService
      .getSystemNotifications()
      .subscribe((systemNotifications: Array<SystemNotificationModel>) => {
        this.systemNotifications = systemNotifications;

        if (this.systemNotifications.length === 0) {
          this.hasNotification = false;
          return;
        }

        this.hasNotification =
          this.systemNotifications.find((systemNotification: SystemNotificationModel) => systemNotification.readAt === null) !== undefined;
      });
  }

  private initUser(): void {
    this.subscription = this.authenticationService.currentUser.subscribe((user: UserModel | null) => {
      this.user = user;
    });
  }
}
