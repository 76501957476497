import { EnvironmentModel } from '../app/core/models/environment.model';

export const environment: EnvironmentModel = {
  firebase: {
    projectId: 'bed4dog-bde',
    appId: '1:957954710099:web:80ce9bb9654f357f3e84a6',
    storageBucket: 'bed4dog-bde.appspot.com',
    apiKey: 'AIzaSyAzPAwCKhyrEpsKTg5WB48sVx124woYu68',
    authDomain: 'bed4dog-bde.firebaseapp.com',
    messagingSenderId: '957954710099',
    measurementId: 'G-M1LPZHWPCF',
  },
  production: true,
  appUrl: 'https://app.pawaway.com',
  apiUrl: 'https://api.pawaway.com/api',
  mediaUrl: 'https://api.pawaway.com/storage/',
  hotjar: {
    trackingCode: '3896196',
    version: 6,
  },
  strapi: {
    url: 'https://cms.pawaway.com/api',
    token:
      // eslint-disable-next-line max-len
      '2c84c6a4d6a9a6409b981b57ef0a48bba259259f43a90d85bba6195e63b7f86005503351029b079cdaae4cebaf1446d9c9be9d29cb05691426c4ac493ecbcb89bd13e1c1acd78b3e805a376d27b80acc87a78f709abc4dad8480f291abcef4b2250d15cc97b177f810e78715be74ce7fd914da2fa84948c0df07cd153bd38e10',
    mediaUrl: 'https://cms.pawaway.com',
  },
};
