import { UserModel } from '../models/user.model';
import { UserDto } from '../dtos/user.dto';

export class UserCreator {
  public static create(dto: UserDto): UserModel {
    return {
      id: dto.id,
      completionStatus: dto.completion_status,
      firstName: dto.first_name,
      lastName: dto.last_name,
      birthDate: dto.birth_date,
      nationality: dto.nationality,
      gender: dto.gender,
      street: dto.street,
      postalCode: dto.zip_code,
      city: dto.city,
      latitude: dto.latitude,
      longitude: dto.longitude,
      why: dto.why,
      nip: dto.nip,
      companyName: dto.hotel_name,
      phoneNumber: dto.phone_number,
      marketingChannel: dto.marketing_channel,
      companyType: dto.business_type,
      pictureUrl: dto.picture_url,
      slug: dto.slug,
      motto: dto.my_motto,
      differences: dto.what_makes_me_different,
      experience: dto.experience_years,
      email: dto.email,
      languages: dto.languages ? dto.languages : [],
      stripeAccountId: dto.stripe_account_id,
      verificationStatus: dto.verification_status,
      verificationStatusReason: dto.verification_status_reason,
    };
  }
}
