import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

import { StrapiMediaModel } from '../models/strapi-media.model';

@Pipe({
  name: 'strapiMediaUrl',
})
export class StrapiMediaUrlPipe implements PipeTransform {
  public transform(value: string | StrapiMediaModel): string {
    if (typeof value === 'object') {
      return `${environment.strapi.mediaUrl}${value.data.attributes.url}`;
    }

    return `${environment.strapi.mediaUrl}${value}`;
  }
}
