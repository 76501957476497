import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpParamsUtil } from '../../shared/utils/http-params.util';
import { SystemNotificationModel } from '../models/system-notification.model';
import { SystemNotificationDto } from '../dtos/system-notification.dto';
import { SystemNotificationCreator } from '../creators/system-notification.creator';
import { ListDto } from '../../pagination/dtos/list.dto';
import { PaginationDataDto } from '../../pagination/dtos/pagination-data.dto';
import { PaginationDataDtoCreator } from '../../pagination/creators/pagination-data-dto.creator';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationsService {
  constructor(private httpClient: HttpClient) {}

  public getSystemNotifications(): Observable<Array<SystemNotificationModel>> {
    const params: HttpParams = HttpParamsUtil.mapFiltersToHttpParams<PaginationDataDto>(
      PaginationDataDtoCreator.create({ page: 1, itemsPerPage: 1000 })
    );

    return this.httpClient
      .get<ListDto<SystemNotificationDto>>(`${environment.apiUrl}/system-notifications`, { params })
      .pipe(
        map((listDto: ListDto<SystemNotificationDto>) =>
          listDto.data.map((dto: SystemNotificationDto) => SystemNotificationCreator.create(dto))
        )
      );
  }

  public getSystemNotification(notificationId: number): Observable<SystemNotificationModel> {
    return this.httpClient
      .get<SystemNotificationDto>(`${environment.apiUrl}/system-notifications/${notificationId}`)
      .pipe(map((dto: SystemNotificationDto) => SystemNotificationCreator.create(dto)));
  }

  public deleteNotification(notificationId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/system-notifications/${notificationId}`);
  }

  public getUnreadStatus(orderId: number | null): Observable<boolean> {
    const params: HttpParams = HttpParamsUtil.mapFiltersToHttpParams({ ...(orderId && { order_id: orderId }) });

    return this.httpClient.get<boolean>(`${environment.apiUrl}/system-notifications/unreaded`, { params });
  }

  public setReadStatus(orderId: number | null): Observable<void> {
    const params: HttpParams = HttpParamsUtil.mapFiltersToHttpParams({ ...(orderId && { order_id: orderId }) });

    return this.httpClient.get<void>(`${environment.apiUrl}/system-notifications/read`, { params });
  }
}
