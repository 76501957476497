import { RegisterUserFormModel } from '../models/register-user-form.model';
import { RegisterUserFormDto } from '../dtos/register-user-form.dto';
import { CompanyTypeEnum } from '../enums/company-type.enum';

export class RegisterUserFormCreator {
  public static create(model: RegisterUserFormModel): RegisterUserFormDto {
    return {
      business_type: CompanyTypeEnum.owner,
      first_name: model.firstName,
      last_name: model.lastName,
      phone_number: `${model.prefix}${model.phoneNumber}`,
      email: model.email,
      password: model.password,
      password_confirmation: model.confirmationPassword,
      acceptet_privacy_regulations: model.privacyRegulations,
      acceptet_merketing: model.marketingRegulations,
      ...(model.referralCode && { referral_code: model.referralCode }),
    };
  }
}
