import { forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrentLanguageService } from '../../i18n/services/current-language.service';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { CurrentSettingsService } from '../../settings/services/current-settings.service';
import { UserVerificationService } from '../../user-verification/services/user-verification.service';

export const AppInitializerFactory = (
  currentLanguageService: CurrentLanguageService,
  authenticationService: AuthenticationService,
  currentSettingsService: CurrentSettingsService,
  userVerificationService: UserVerificationService
): (() => Promise<void>) => {
  const initData$: Observable<void> = forkJoin([
    currentLanguageService.initCurrentLanguage(),
    authenticationService.initCurrentUser(),
    currentSettingsService.initSettings(),
    userVerificationService.initCurrentUserVerificationStatus(),
  ]).pipe(
    map(() => void 0),
    catchError(() => of(void 0))
  );

  return (): Promise<void> => lastValueFrom(initData$);
};
