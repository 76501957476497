/* eslint-disable @typescript-eslint/typedef */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/guards/auth.guard';
import { NoAuthGuard } from './auth/guards/noauth.guard';
import { AccountCreationCompletedGuard } from './first-visit/guards/account-creation-completed.guard';
import { DashboardLayoutComponent } from './layout/components/dashboard-layout/dashboard-layout.component';
import { DefaultLayoutComponent } from './layout/components/default-layout/default-layout.component';
import { AccountCreationNotCompletedGuard } from './first-visit/guards/account-creation-not-completed.guard';
import { ReferrerRedirectComponent } from './auth/pages/referrar-redirect/referrer-redirect.component';
import { NotFoundComponent } from './core/pages/not-found/not-found.component';

const routes: Routes = [
  { path: 'r/:referralCode', component: ReferrerRedirectComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'p',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'verify-user',
    loadChildren: () => import('./user-verification/user-verification.module').then((m) => m.UserVerificationModule),
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule), canActivate: [NoAuthGuard] },
  {
    path: 'first-visit',
    loadChildren: () => import('./first-visit/first-visit.module').then((m) => m.FirstVisitModule),
    canActivate: [AuthGuard, AccountCreationNotCompletedGuard],
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard, AccountCreationCompletedGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'services',
        loadChildren: () => import('./dashboard-services/dashboard-services.module').then((m) => m.DashboardServicesModule),
      },
      {
        path: 'earnings',
        loadChildren: () => import('./dashboard-earnings/dashboard-earnings.module').then((m) => m.DashboardEarningsModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('./dashboard-calendar/dashboard-calendar.module').then((m) => m.DashboardCalendarModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./dashboard-settings/dashboard-settings.module').then((m) => m.DashboardSettingsModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./dashboard-profile/dashboard-profile.module').then((m) => m.DashboardProfileModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./dashboard-orders/dashboard-orders.module').then((m) => m.DashboardOrdersModule),
      },
    ],
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
