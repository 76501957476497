import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'JSONP') {
      return next.handle(request);
    }

    const token: string | null = localStorage.getItem('authorisationToken');

    if (token !== null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap({
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                this.authenticationService.resetUserAndRedirect();
                break;
              case 403:
                this.authenticationService.resetUserAndRedirect();
                break;
              default:
                break;
            }
          }
        },
      })
    );
  }
}
