import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

import { LanguageEnum } from '../enums/language.enum';

export const CURRENT_LANGUAGE_KEY: string = 'currentLanguage';

@Injectable({
  providedIn: 'root',
})
export class CurrentLanguageService {
  private currentLanguage$: BehaviorSubject<string>;
  private readonly defaultLanguage: string;

  constructor(private translateService: TranslateService) {
    this.defaultLanguage = LanguageEnum.pl;
    this.currentLanguage$ = new BehaviorSubject<string>(this.defaultLanguage);
    this.translateService.setDefaultLang(this.defaultLanguage);
  }

  public initCurrentLanguage(): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this.setCurrentLanguage(this.defaultLanguage);
      })
    );
  }

  public getCurrentLanguageAsObservable(): Observable<string> {
    return this.currentLanguage$.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage$.value;
  }

  private setCurrentLanguage(language: string): void {
    localStorage.setItem(CURRENT_LANGUAGE_KEY, language);
    moment.locale(language);
    this.translateService.use(language);
    this.currentLanguage$.next(language);
  }
}
