import { Component, OnInit } from '@angular/core';

import { switchMap } from 'rxjs';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { UserVerificationService } from '../../services/user-verification.service';
import { UserVerificationStatusModel } from '../../models/user-verification-status.model';
import { AuthenticationService } from '../../../auth/services/authentication.service';

@Component({
  selector: 'app-user-verification-alert',
  templateUrl: './user-verification-alert.component.html',
  styleUrls: ['./user-verification-alert.component.scss'],
})
export class UserVerificationAlertComponent extends SubscriptionStoreComponent implements OnInit {
  public userVerificationStatus: UserVerificationStatusModel | null;
  public isFilledPetsitterProfileVisible: boolean;
  public isFilledGalleryVisible: boolean;
  public isFilledServiceVisible: boolean;
  public isFilledStripeVisible: boolean;
  public isFilledMyPlaceVisible: boolean;
  public isSendToVerificationVisible: boolean;

  constructor(private verificationService: UserVerificationService, private authenticationService: AuthenticationService) {
    super();
    this.userVerificationStatus = null;
    this.isFilledPetsitterProfileVisible = false;
    this.isFilledGalleryVisible = false;
    this.isFilledServiceVisible = false;
    this.isFilledStripeVisible = false;
    this.isFilledMyPlaceVisible = false;
    this.isSendToVerificationVisible = false;
  }

  public ngOnInit(): void {
    this.initUserVerificationStatus();
  }

  public sendToVerification(): void {
    this.subscription = this.verificationService
      .sendToVerification()
      .pipe(
        switchMap(() => {
          return this.authenticationService.initCurrentUser();
        })
      )
      .subscribe();
  }

  private initUserVerificationStatus(): void {
    this.subscription = this.verificationService.currentUserVerificationStatus.subscribe(
      (userVerificationStatus: UserVerificationStatusModel | null) => {
        if (!userVerificationStatus) {
          return;
        }

        this.userVerificationStatus = userVerificationStatus;

        this.isFilledPetsitterProfileVisible = false;
        this.isFilledGalleryVisible = false;
        this.isFilledServiceVisible = false;
        this.isFilledStripeVisible = false;
        this.isFilledMyPlaceVisible = false;
        this.isSendToVerificationVisible = false;

        this.initIsAlertVisible();
        this.messagesOrder();
      }
    );
  }

  private initIsAlertVisible(): void {
    if (!this.userVerificationStatus) {
      return;
    }
  }

  private messagesOrder(): void {
    if (!this.userVerificationStatus) {
      return;
    }

    if (!this.userVerificationStatus.filledPetsitterProfile) {
      this.isFilledPetsitterProfileVisible = true;
      return;
    }

    if (!this.userVerificationStatus.filledGallery) {
      this.isFilledGalleryVisible = true;
      return;
    }

    if (!this.userVerificationStatus.filledService) {
      this.isFilledServiceVisible = true;
      return;
    }

    if (!this.userVerificationStatus.filledMyPlace) {
      this.isFilledMyPlaceVisible = true;
      return;
    }

    if (!this.userVerificationStatus.filledStripe) {
      this.isFilledStripeVisible = true;
      return;
    }

    this.isSendToVerificationVisible = true;
  }
}
