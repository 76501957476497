import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription-store',
  template: '',
})
export class SubscriptionStoreComponent implements OnDestroy {
  protected storeSubscription: Subscription;

  protected set subscription(subscription: Subscription | undefined) {
    if (subscription === undefined) {
      return;
    }

    this.addSubscription(subscription);
  }

  constructor() {
    this.storeSubscription = new Subscription();
  }

  public ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
  }

  protected addSubscription(subscription: Subscription): void {
    this.storeSubscription.add(subscription);
  }
}
