import { SettingsDto } from '../dtos/settings.dto';
import { SettingsModel } from '../models/settings.model';

export class SettingsCreator {
  public static create(dto: SettingsDto): SettingsModel {
    return {
      commission: dto.commission,
    };
  }
}
