import { Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, Validators } from '@angular/forms';

import { map } from 'rxjs/operators';
import { Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-form-control-base',
  template: '',
})
export class FormControlBaseComponent<ControlValueType> implements OnInit, ControlValueAccessor {
  @Input() public uid: string;
  @Input() public label: string;
  @Input() public canShowErrors: boolean;
  @Input() public isDisabled: boolean;
  @Input() public isRequiredAsteriskVisible: boolean;
  @Input() public isInlineLabel: boolean;

  public control: AbstractControl | null;
  public isLabel: boolean;
  public isRequired$?: Observable<boolean>;

  constructor(@Optional() protected ngControl: NgControl) {
    this.uid = '';
    this.label = '';
    this.canShowErrors = false;
    this.isDisabled = false;
    this.control = null;
    this.isLabel = false;
    this.isInlineLabel = false;
    this.isRequiredAsteriskVisible = true;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.initControl();
    this.initIsLabelState();
  }

  public onChange(value: ControlValueType | Array<ControlValueType>): ControlValueType | Array<ControlValueType> {
    return value;
  }

  public registerOnChange(fn: () => ControlValueType): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public writeValue(value: ControlValueType | Array<ControlValueType>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched(): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private initControl(): void {
    if (this.ngControl) {
      this.control = this.ngControl.control;

      if (this.control?.statusChanges) {
        this.isRequired$ = this.control?.statusChanges.pipe(
          startWith(this.control?.status),
          map(() => !!this.control?.hasValidator(Validators.required) && this.isRequiredAsteriskVisible)
        );
      }
    }
  }

  private initIsLabelState(): void {
    this.isLabel = !!this.label;
  }
}
